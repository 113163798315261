<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <div>
      <UserForm :user="user" :info="true" v-if="loaded" :readOnly="true" />
      <b-card title="Scans">
        <b-row>
          <b-col
          md="12"
          xl="12"
          class="mb-1">
            <div v-if="scans.length==0">
              Aucun scan pour cet utilisateur.
            </div>
            <div v-else>
              <vue-good-table
                :columns="columnsScan"
                :rows="scans"
                :pagination-options="{
                  enabled: false
                }"
              >
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                 

                  <!-- Column: Action -->
                  
                  <span v-if="props.column.field === 'action'">
                    <span>
                     
                     <b-button
                        @click="$router.push({name:'scanDetail', params: { id: props.row.id }})"
                        >
                        Voir le scan
                        </b-button>
                    </span>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
               
              </vue-good-table>
              <ScanPopup :scan_id="currentScan" v-if="displayScanPopup" />
            </div>
          </b-col>
        </b-row>
      </b-card>
      <b-card title="Adresses">
        <b-row>
          <b-col
          md="12"
          xl="12"
          class="mb-1">
            <div v-if="addresses.length==0">
              Aucune adresse pour cet utilisateur.
            </div>
            <div v-else>
              <div v-for="address in addresses" :key="address.id">
                <b>{{address.name}}</b><br />
                Nom : {{address.first_name}}<br />
                Prénom : {{address.last_name}}<br />
                Téléphone : {{address.phone}}<br />
                Adresse 1 : {{address.address1}}<br />
                Adresse 2 : {{address.address2}}<br />
                Code postal : {{address.postcode}}<br />
                Ville : {{address.city}}<br />
                Pays : {{address.country}}<br />
                Adresse par défaut : {{address.default_address}}<br /><br />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <b-card title="Ordonnances">
        <b-row>
          <b-col
          md="12"
          xl="12"
          class="mb-1">
            <div v-if="prescriptions.length==0">
              Aucune ordonnance pour cet utilisateur.
            </div>
            <div v-else>
              <vue-good-table
                :columns="columnsPrescription"
                :rows="prescriptions"
                :pagination-options="{
                  enabled: false
                }"
              >
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                 
                  <!-- Column: Action -->
                  
                  <span v-if="props.column.field === 'action'">
                    <span>
                     <b-button
                        @click="displayPrescription(props.row.id)"
                        >
                        Voir l'ordonnance
                        </b-button>
                    </span>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
               
              </vue-good-table>
              <PrescriptionPopup :prescription_id="currentPrescription" v-if="displayPrescriptionPopup" />
            </div>
          </b-col>
        </b-row>
      </b-card>

      <b-card title="Commandes">
        <b-row>
          <b-col
          md="12"
          xl="12"
          class="mb-1">
            <div v-if="orders.length==0">
              Aucune commande pour cet utilisateur.
            </div>
            <div v-else>
              <vue-good-table
                :columns="columns"
                :rows="orders"
                :pagination-options="{
                  enabled: false
                }"
              >
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <span v-if="props.column.field === 'status'">
                    <b-badge v-if="props.row.status=='completed'" variant="success">Terminée</b-badge>
                    <b-badge v-else-if="props.row.status=='processing'" variant="warning">en cours</b-badge>
                    <b-badge v-else variant="danger">{{props.row.status}}</b-badge>
                  </span>

                  <!-- Column: Action -->
                  <span v-else-if="props.column.field === 'amount'">
                    {{ props.formattedRow[props.column.field] }}€
                  </span>
                  <span v-else-if="props.column.field === 'action'">
                    <span>
                      <b-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                      >
                        <template v-slot:button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="text-body align-middle mr-25"
                          />
                        </template>
                        <b-dropdown-item  @click="$router.push({name:'orderDetail', params: { id: props.row.id }})">
                          <feather-icon
                            icon="Edit2Icon"
                            class="mr-50"
                          />
                          <span>Voir</span>
                        </b-dropdown-item>
                      
                      </b-dropdown>
                    </span>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
               
              </vue-good-table>
            </div>
          </b-col>
        </b-row>
      </b-card>
  </div>
</b-overlay>
</template>

<script>
import {BModal,BOverlay, BButton, VBModal, BCard, BCol, BRow, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest, Auth, Woocommerce} from '@simb2s/senseye-sdk'
import ScanPopup from '../../components/ScanPopup.vue'
import { VueGoodTable } from 'vue-good-table'
import UserForm from './UserForm.vue'
import emitter from 'tiny-emitter/instance'
import PrescriptionPopup from '../../components/PrescriptionPopup.vue'

export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BBadge,
    BOverlay,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton, 
    BCol,
    BRow,
    UserForm,
    ScanPopup,
    PrescriptionPopup
  },
  data() {
    return {
      user:null,
      scans:[],
      prescriptions:[],
      orders:[],
      addresses:[],
      loaded:false,
      loading:true,
      displayScanPopup:false,
      currentScan:null,
      currentPrescription:null,
      displayPrescriptionPopup:false,
      columns: [
        {
          label: 'Date',
          field: 'date',
        },
        {
          label: 'Total',
          field: 'amount',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      columnsScan: [
        {
          label: 'Nom',
          field: 'name',
        },
        {
          label: 'Date',
          field: 'date',
        },
        {
          label: 'Scan par défaut',
          field: 'default_scan',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      columnsPrescription: [
        {
          label: 'Nom',
          field: 'name',
        },
        
        {
          label: 'Action',
          field: 'action',
        },
      ],
    }
  },
  methods:{
    displayPrescription(id) {
      this.currentPrescription=id
      this.displayPrescriptionPopup=true
    },
    displayScan(id)
    {
      this.currentScan = id
      this.displayScanPopup = true
    },
    async getUserScans() {
      let scans = await APIRequest.list('scans', this.$route.params.id)
      this.scans = scans[0]

    },
    async getUserAddresses() {
      let addresses = await APIRequest.list('addresses', this.$route.params.id)
      this.addresses = addresses[0]

    },
    async getUserPrescriptions() {
      let prescriptions = await APIRequest.list('prescriptions', this.$route.params.id)
      this.prescriptions = prescriptions[0]

    },
    async getUserOrders() {
      let orders = await APIRequest.list('orders', this.$route.params.id)
      orders=orders[0]
      
      for(let i=0;i<orders.length;i++)
      {
        let item={}
        item=orders[i]
        if(orders[i].woo_order)
        {
      
        let order = await Woocommerce.detail('orders',orders[i].woo_order)
        item.status=order.status
        }
        else
        {
          item.status='non complétée'
        }

        this.orders.push(item)
      }
    }
  },
  async mounted()
  {
    emitter.on('hide-popup-scan', function () {
      this.displayScanPopup=false
    }, this);

    emitter.on('hide-popup-prescription', function () {
      this.displayPrescriptionPopup=false
    }, this);

    this.user=await Auth.get_user_with_id(this.$route.params.id)
    
    await this.getUserScans()
    await this.getUserPrescriptions()
    await this.getUserOrders()
    await this.getUserAddresses()
    this.loaded=true
    this.loading=false
  }
}
</script>
